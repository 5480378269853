import { Flex } from "antd";
import { sonIcon, moonIcon } from "../../../../../../utils/svgIcons";
import { colors } from "../../../../../../utils/service";
import CustomeSvgIcon from "../../../../../../components/common/CustomSvgIcon";
import useWindowSize from "../../../../../../components/hooks/useWindowSize";

const styleIcon = { marginBottom: "0.75rem", fill: colors.grayBackgroundColor };
const styleIconActive = { marginBottom: "0.75rem", fill: colors.mainThemeColor };

const WeatherVote = ({ dataUserVote, onUserVote }) => {
  const { isMobileView } = useWindowSize();

  const iconHeight = !isMobileView ? "1.875rem" : "2rem";

  return (
    <Flex justify="space-around">
      <Flex vertical align="center">
        <Flex vertical align="center" className="vfra-vote-option" onClick={() => onUserVote("weathers", "day")}>
          <CustomeSvgIcon
            style={dataUserVote?.weathers?.day ? styleIconActive : styleIcon}
            width="40"
            height={iconHeight}
            icon={sonIcon}
          />
          <Flex vertical align="center">
            <span>day</span>
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical align="center">
        <Flex vertical align="center" className="vfra-vote-option" onClick={() => onUserVote("weathers", "night")}>
          <CustomeSvgIcon
            style={dataUserVote?.weathers?.night ? styleIconActive : styleIcon}
            height={iconHeight}
            icon={moonIcon}
          />

          <Flex vertical align="center">
            <span>night</span>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WeatherVote;
