import "./index.css";
import { useState, useRef, useEffect } from "react";
import { Avatar, Flex, Input, Button, message } from "antd";
import { useAuth } from "../../contexts/AuthContext";

const { TextArea } = Input;

const CommentReplyArea = ({ data, topic, onAddComment, onCancel, isSmallView = false, addFunc, ...props }) => {
  const textareaRef = useRef(null);
  const { user } = useAuth();
  const [valueComment, setValueComment] = useState(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const doComment = async () => {
    if (!valueComment || !valueComment.trim()) return;
    if (sending) {
      message.warning("Please wait before submitting another comment.");
      return;
    }

    const comment = {
      comment: valueComment.trim(),
      mentioned_user_ids: [],
      parent_id: data.id,
      topic: topic,
    };

    try {
      setSending(true);
      const { error, data } = await addFunc(comment);
      if (error) {
        message.error(error.message);
      } else {
        setValueComment(null);
        onAddComment(data[0]);
        message.success("Comment added successfully!");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSending(false);
    }
  };

  const textAreaPlaceholder = `Add a reply to ${data.user_name}`;

  return (
    <Flex vertical={isSmallView} align={isSmallView ? "" : "center"} {...props} className="vfra-comment-reply-area">
      {!isSmallView && (
        <div>
          <Avatar size="large" src={user.vfra_user?.avatar ?? user.avatar_url} />
        </div>
      )}

      <TextArea
        ref={textareaRef}
        style={{ fontSize: isSmallView ? "0.8125rem" : "0.9rem" }}
        placeholder={textAreaPlaceholder}
        autoSize={{
          minRows: 1,
        }}
        maxLength={2000}
        variant="borderless"
        value={valueComment}
        onChange={(e) => setValueComment(e.target.value)}
      />

      <Flex gap={"0.5rem"} align="center" justify={"end"}>
        <Button
          size={isSmallView ? "small" : "middle"}
          shape="round"
          type="text"
          style={{ fontSize: isSmallView ? "0.7rem" : "0.75rem", fontWeight: 500, color: "var(--gray-color)" }}
          onClick={onCancel}>
          Cancel
        </Button>

        <Button
          size={isSmallView ? "small" : "default"}
          disabled={sending}
          loading={sending}
          onClick={doComment}
          shape="round"
          style={{ fontSize: isSmallView ? "0.7rem" : "0.75rem", fontWeight: 500, color: "var(--gray-color)" }}>
          Reply
        </Button>
      </Flex>
    </Flex>
  );
};

export default CommentReplyArea;
