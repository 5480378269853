import "./index.css";
import { useState, useEffect } from "react";
import { Flex } from "antd";
import CustomProgress from "../../../../../components/common/CustomProgress";
import { getTotalFromObject } from "../../../../../utils/service";

const GenderVote = ({ data }) => {
  const [female, setFemale] = useState(null);
  const [unisex, setUnisex] = useState(null);
  const [male, setMale] = useState(null);

  const [dataVote, setDataVote] = useState(null);
  const setInitValues = (dataInit) => {
    setDataVote(dataInit);
    const total = getTotalFromObject(dataInit);

    const initFemale = dataInit?.female ? (dataInit.female / total) * 100 : 0;
    setFemale(initFemale);

    const initUnisex = dataInit?.unisex ? (dataInit.unisex / total) * 100 : 0;
    setUnisex(initUnisex);

    const initMale = dataInit?.male ? (dataInit.male / total) * 100 : 0;
    setMale(initMale);
  };

  useEffect(() => {
    setInitValues(data);
  }, [data]);

  const isShowProgress = female + unisex + male > 0 ? true : false;

  return (
    <Flex vertical gap={"0.75rem"}>
      <Flex justify="space-between" className="vfra-vote-value">
        <div>
          <span>female </span>
          <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({dataVote ? dataVote.female : 0})</span>
        </div>
        <div>
          <span>unisex </span>
          <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({dataVote ? dataVote.unisex : 0})</span>
        </div>
        <div>
          <span>male </span>
          <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({dataVote ? dataVote.male : 0})</span>
        </div>
      </Flex>
      {isShowProgress && <CustomProgress data={[female?.toFixed(0), unisex?.toFixed(0), male?.toFixed(0)]} />}
    </Flex>
  );
};

export default GenderVote;
