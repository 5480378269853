import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Flex, Skeleton, message, Row, Col, Tabs, Divider } from "antd";
import { getPerfumeById, addVotes, getPerfumeRates, getOwnVotes } from "../../../services/supabaseClient";
import { useAuth } from "../../../components/contexts/AuthContext";
import { getPerfumeDescription } from "../../../utils/service";
import { usePerfume } from "../../../components/contexts/PefumeContext";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PerfumeInfor from "./PerfumeInfor";
import RelatedPosts from "./RelatedPosts";
import Votes from "./Votes";
import Notes from "./Notes";
import Accords from "./Accords";
import Occasion from "./Ocassion";

const PerfumeDetail = () => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { perfumeId } = useParams();
  const { setPerfumeContext } = usePerfume();

  const [loading, setLoading] = useState(true);
  const [perfume, setPerfume] = useState(null);

  //voting
  const [dataVotes, setDataVotes] = useState(null);
  const [dataVotesByUser, setDataVotesByUser] = useState(null);

  const [rateData, setRateData] = useState(null);
  const [valueRate, setValueRate] = useState(null);

  const fetchDataPerfume = useCallback(async () => {
    try {
      const { data, error } = await getPerfumeById(perfumeId);
      if (error) {
        message.error(error.message);
        setPerfume(null);
        setPerfumeContext(null);
      } else {
        setPerfume(data[0]);
        setPerfumeContext(data[0]);
      }
    } catch (error) {
      setPerfume(null);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [perfumeId, setPerfumeContext]);

  const fetchDataVotes = useCallback(async () => {
    try {
      const { data, error } = await getPerfumeRates(perfumeId);
      if (error) {
        message.error(error.message);
      } else {
        const newRate = data[0]?.rate ? data[0]?.rate : null;
        setRateData(newRate);
        setDataVotes(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [perfumeId]);

  const fetchDataVotesByUser = useCallback(async () => {
    try {
      const { data, error } = await getOwnVotes(perfumeId);
      if (error) {
        message.error(error.message);
      } else {
        setValueRate(data[0]?.rate ?? null);
        setDataVotesByUser(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [perfumeId]);

  useEffect(() => {
    fetchDataPerfume();
    fetchDataVotes();

    if (user) fetchDataVotesByUser();
    else setDataVotesByUser(null);
  }, [user, fetchDataPerfume, fetchDataVotes, fetchDataVotesByUser]);

  const updateDataVote = (oldValueVote, newValueVote) => {
    let count = rateData?.count ?? 0;
    const oldTotal = count ? rateData.value * count : 0;
    let newAverageScore = 0;
    if (oldValueVote) {
      newAverageScore = (oldTotal - oldValueVote + newValueVote) / count;
    } else {
      count = count + 1;
      newAverageScore = (oldTotal + newValueVote) / count;
    }

    const newData = {
      count,
      value: newAverageScore,
    };

    setRateData(newData);
  };

  const onRate = async (newValue) => {
    if (!user) message.warning("Please sign in to rate!");
    else {
      try {
        const dataRate = {
          rate: newValue.toFixed(0),
        };

        const { error } = await addVotes(perfumeId, dataRate);

        if (error) {
          message.error(error.message);
        } else {
          updateDataVote(valueRate, newValue);
          setValueRate(newValue);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const items = perfume
    ? [
        {
          key: "0",
          label: <span style={{ fontSize: "0.9rem" }}>Ocassion</span>,
          children: <Occasion noteCount={perfume.notes.length} />,
        },
        {
          key: "1",
          label: <span style={{ fontSize: "0.9rem" }}>Main accords</span>,
          children: <Accords showTitle={false} accords={perfume.accords} />,
        },
      ]
    : null;

  const handleOnChangeVote = () => {
    fetchDataVotes();
  };

  return (
    <>
      {loading && (
        <Skeleton
          active
          paragraph={{
            rows: 20,
          }}
        />
      )}

      {!loading && !perfume && (
        <Flex align="center" justify="center">
          <p style={{ color: "rgb(100 100 100)", fontSize: "0.8125rem" }}>Data not found!</p>
        </Flex>
      )}

      {!loading && perfume && (
        <>
          <Helmet>
            <title>Scentub - {perfume.name}</title>
            <meta name="description" content={getPerfumeDescription(perfume)} />
          </Helmet>

          <Row gutter={[24, 24]}>
            <Col span={isMobileView ? 24 : 10}>
              <PerfumeInfor perfume={perfume} rateData={rateData} onRate={onRate} />
            </Col>
            <Col span={isMobileView ? 24 : 14}>
              <Votes
                onChangVote={handleOnChangeVote}
                perfumeName={perfume.name}
                dataVotes={dataVotes}
                dataVotesByUser={dataVotesByUser}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={isMobileView ? 24 : 10}>
              {isMobileView && <Divider style={{ margin: "1rem 0" }} />}
              {/* {perfume.accords.length > 0 && <Accords accords={perfume.accords} />} */}
              <Tabs style={{ zIndex: 100 }} size="small" centered items={items} />
            </Col>
            <Col span={isMobileView ? 24 : 14}>{perfume.notes.length > 0 && <Notes notes={perfume.notes} />}</Col>
          </Row>
          <RelatedPosts />
        </>
      )}
    </>
  );
};

export default PerfumeDetail;
