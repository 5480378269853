import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://spfycswrpbrwxqetirar.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNwZnljc3dycGJyd3hxZXRpcmFyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg1MzgwMzgsImV4cCI6MjAzNDExNDAzOH0._3A2ujEUu1yM5k8jlWONDg7PaEl1X2H7p6f0ZX0LRzk";

// const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://api.perfumerates.com";
const endpoint = "https://api.perfumerates.com";

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  autoRefreshToken: true,
});

// const getValidAccessToken = async () => {
//   try {
//     const {
//       data: { session },
//     } = await supabase.auth.getSession();

//     if (session) {
//       const { access_token, expires_at, refresh_token } = session;
//       const currentTime = Math.floor(Date.now() / 1000);

//       if (currentTime >= expires_at) {
//         // Token has expired, refresh the session
//         const { data, error } = await supabase.auth.refreshSession({ refresh_token });
//         if (error) {
//           return null;
//         } else {
//           return data.session.access_token;
//         }
//       }
//       return access_token;
//     }
//     return null;
//   } catch (error) {
//     return null;
//   }
// };

// const fetchBaseOld = async (method, url, body, isAnon = false) => {
//   try {
//     let headers = {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     };

//     if (!isAnon) {
//       const accessToken = await getValidAccessToken();

//       if (accessToken) {
//         headers = { ...headers, Authorization: `Bearer ${accessToken}` };
//       }
//     }

//     const response = await fetch(url, {
//       method: method,
//       headers,
//       body: body ? JSON.stringify(body) : null,
//     });

//     if (response.status === 401) {
//       supabase.auth.signOut();
//       return {
//         status: response.status,
//       };
//     }

//     if (response.status === 204) {
//       return {
//         status: response.status,
//       };
//     }

//     if (!response.ok) {
//       throw new Error(response.statusText);
//     }

//     const results = await response.json();
//     return results;
//   } catch (error) {
//     return { status: 204, error: error };
//   }
// };

const fetchBase = async (method, url, body, isAnon = false) => {
  try {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (!isAnon) {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session && session.access_token) {
        headers = { ...headers, Authorization: `Bearer ${session.access_token}` };
      }
    }

    const response = await fetch(url, {
      method: method,
      headers,
      body: body ? JSON.stringify(body) : null,
    });

    if (response.status === 401) {
      supabase.auth.signOut();
      return {
        status: response.status,
      };
    }

    if (response.status === 204) {
      return {
        status: response.status,
      };
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const results = await response.json();
    return results;
  } catch (error) {
    return { status: 204, error: error };
  }
};

//======================== PERFUMES =============================

// GET perfumes/:id
export const getPerfumeById = async (pid) => {
  return fetchBase("GET", `${endpoint}/perfumes/${pid}`, null, true);
};

// GET perfumes/activity/:id
export const getActitityById = async (pid) => {
  return fetchBase("GET", `${endpoint}/perfumes/activity/${pid}`, null, true);
};

//======================== PERFUMES - VOTES =============================
// GET perfumes/:id/votes
export const getPerfumeRates = async (pid) => {
  return fetchBase("GET", `${endpoint}/perfumes/${pid}/votes`);
};

// GET perfumes/:id/votes/me
export const getOwnVotes = async (pid) => {
  return fetchBase("GET", `${endpoint}/perfumes/${pid}/votes/me`, null);
};

// POST perfumes/:id/votes/me
export const addVotes = async (pid, objectDataVote) => {
  return fetchBase("POST", `${endpoint}/perfumes/${pid}/votes/me`, objectDataVote);
};

// GET perfumes/posts/:id?pageSize=
export const getPostByPerfume = async (pid, pageSize, startAfterPost = 0) => {
  return fetchBase("GET", `${endpoint}/perfumes/posts/${pid}?startAfterPost=${startAfterPost}&pageSize=${pageSize}`);
};

//======================== DESIGNERS ============================

// GET brands/lite
export const getAllBrandsLite = async () => {
  return fetchBase("GET", `${endpoint}/brands/lite`, null, true);
};

// GET brands/:id
export const getBrandById = async (brandId) => {
  return fetchBase("GET", `${endpoint}/brands/${brandId}`, null, true);
};

//======================== COMMENTS =============================

// GET comments/users/:uid?startTime=:startTime&pageSize=:pageSize
export const getCommentsByUser = (uid, startTime, pageSize) => {
  return fetchBase("GET", `${endpoint}/users/comments/${uid}?startTime=${startTime}&pageSize=${pageSize}`);
};

// GET comments/perfumes/:topic?startTime=:startTime&pageSize=:pageSize
export const getCommentsByTopic = (topic, startTime, pageSize, parentId = null) => {
  if (parentId)
    return fetchBase(
      "GET",
      `${endpoint}/perfumes/comments/${topic}?parentId=${parentId}&startTime=${startTime}&pageSize=${pageSize}`
    );
  else return fetchBase("GET", `${endpoint}/perfumes/comments/${topic}?startTime=${startTime}&pageSize=${pageSize}`);
};

// POST perfumes/comments/:id
export const addComment = async (payload) => {
  return fetchBase("POST", `${endpoint}/perfumes/comments/${payload["topic"]}`, payload);
};

// DELETE comments/:id
export const deleteComment = async (id) => {
  return fetchBase("DELETE", `${endpoint}/perfumes/comments/${id}`, null);
};

// POST comments/:id/reactions
export const addCommentReaction = async (payload) => {
  return fetchBase("POST", `${endpoint}/perfumes/comments/${payload["comment_id"]}/reactions`, {
    reaction_type: payload["reaction_type"],
  });
};

// DELETE comments/:id/reactions
export const deleteCommentReaction = async (reaction_type, comment_id) => {
  return fetchBase("DELETE", `${endpoint}/perfumes/comments/${comment_id}/reactions`, {
    reaction_type: reaction_type,
  });
};

// GET /perfumes/comments/f/newest?pageSize:=pageSize
export const getNewestComments = (pageSize) => {
  return fetchBase("GET", `${endpoint}/perfumes/comments/f/newest?pageSize=${pageSize}`);
};

//======================== PROFILE =============================
// GET /users/perfumes?uid=:uid
export const getPerfumes = async (uid) => {
  return fetchBase("GET", `${endpoint}/users/perfumes/?uid=${uid}`);
};

// GET /users/perfumes/requests
export const getRequestPerfumes = async (startTime, pageSize) => {
  return fetchBase("GET", `${endpoint}/users/perfumes/requests?startTime=${startTime}&pageSize=${pageSize}`, null);
};

// POST /users/perfumes/requests
export const addRequestPerfume = async (payload) => {
  return fetchBase("POST", `${endpoint}/users/perfumes/requests`, payload);
};

// DELETE /users/perfumes/requests/:id
export const removeRequestPerfume = async (id) => {
  return fetchBase("DELETE", `${endpoint}/users/perfumes/requests/${id}`, null);
};

// PATCH /users/perfumes/requests/:id
export const updateRequestPerfume = async (id, payload) => {
  return fetchBase("PATCH", `${endpoint}/users/perfumes/requests/${id}`, payload);
};

// GET /users
export const getUser = async (uid) => {
  return fetchBase("GET", `${endpoint}/users/?uid=${uid}`, null, true);
};

// GET /users
export const getUserWithAccessToken = async (uid) => {
  return fetchBase("GET", `${endpoint}/users/?uid=${uid}`, null, false);
};

// GET /users/avatar-upload-key
export const getAvatarUploadKey = async () => {
  return fetchBase("GET", `${endpoint}/users/avatar-upload-key`, null);
};

export const uploadToSignedURL = async (field, path, token, file) => {
  return supabase.storage.from(field).uploadToSignedUrl(path, token, file, {
    cacheControl: "31556926",
    upsert: true,
  });
};

export const getPublicUrl = async (field, fullPath) => {
  return supabase.storage.from(field).getPublicUrl(fullPath);
};

// POST /users
export const updateUserInfo = async (payload) => {
  return fetchBase("POST", `${endpoint}/users`, payload);
};

// GET /users/perfumes/requests/perfume-img-upload-key
export const getImagePerfumeUploadKey = async (id) => {
  return fetchBase("GET", `${endpoint}/users/perfumes/requests/perfume-img-upload-key/${id}`, null);
};

// GET users/perfumes/requests/brand-img-upload-key
export const getLogoBrandUploadKey = async (id) => {
  return fetchBase("GET", `${endpoint}/users/perfumes/requests/brand-img-upload-key/${id}`, null);
};

//======================== EXPLORER =============================
// GET explorer/feeds
export const getFeeds = async (page, pageSize) => {
  return fetchBase("GET", `${endpoint}/explorer/feeds/?page=${page}&pageSize=${pageSize}`, null);
};

// POST explorer/feeds/view
export const markViewedFeeds = (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/feeds/view`, payload);
};

// GET /explorer/post/details
export const getPostDetails = async (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/post/details`, payload);
};

// GET /explorer/comments
export const getCommentsByPost = (topic, startTime, pageSize, parentId = null) => {
  if (parentId)
    return fetchBase(
      "GET",
      `${endpoint}/explorer/comments/${topic}?parentId=${parentId}&startTime=${startTime}&pageSize=${pageSize}`
    );
  else return fetchBase("GET", `${endpoint}/explorer/comments/${topic}?startTime=${startTime}&pageSize=${pageSize}`);
};

// POST /explorer/comments/:id
export const addExplorerComment = async (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/comments/${payload["topic"]}`, payload);
};

// DELETE /explorer/comments/:id
export const deleteExplorerComment = async (id) => {
  return fetchBase("DELETE", `${endpoint}/explorer/comments/${id}`, null);
};

// POST explorer/comments/:id/reactions
export const addExplorerCommentReaction = async (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/comments/${payload["comment_id"]}/reactions`, {
    reaction_type: payload["reaction_type"],
  });
};

// DELETE explorer/comments/:id/reactions
export const removeExplorerCommentReaction = async (reaction_type, comment_id) => {
  return fetchBase("DELETE", `${endpoint}/explorer/comments/${comment_id}/reactions`, {
    reaction_type: reaction_type,
  });
};

//======================== FOLLOW =============================

// GET /users/followers
export const getFollowers = (topic, startTime, pageSize) => {
  return fetchBase("GET", `${endpoint}/users/followers/${topic}?startTime=${startTime}&pageSize=${pageSize}`);
};

// GET /users/followees
export const getFollowees = (topic, startTime, pageSize) => {
  return fetchBase("GET", `${endpoint}/users/followees/${topic}?startTime=${startTime}&pageSize=${pageSize}`);
};

// POST /users/follow
export const follow = async (payload) => {
  return fetchBase("POST", `${endpoint}/users/follow`, payload);
};

// DELETE /users/follow
export const unfollow = async (payload) => {
  return fetchBase("DELETE", `${endpoint}/users/follow`, payload);
};

//======================== POST =============================

// GET /explorer/post
export const getPosts = (topic, startTime, pageSize) => {
  return fetchBase("GET", `${endpoint}/explorer/post/${topic}?startTime=${startTime}&pageSize=${pageSize}`);
};

// POST /explorer/post
export const createPost = async () => {
  return fetchBase("POST", `${endpoint}/explorer/post`, null);
};

// DELETE /explorer/post
export const deletePost = async (id) => {
  return fetchBase("DELETE", `${endpoint}/explorer/post/${id}`, null);
};

// PATCH /explorer/post/:pid
export const updatePost = async (id, payload) => {
  return fetchBase("PATCH", `${endpoint}/explorer/post/${id}`, payload);
};

// GET /explorer/post/post-images-upload-key/:pid?n
export const getPostImagesUploadKey = async (pid, total_image) => {
  return fetchBase("GET", `${endpoint}/explorer/post/post-images-upload-key/${pid}?n=${total_image}`, null);
};

// GET /explorer/post/reactions/type:=typeReaction
export const getPostsByReaction = async (type, startTime, pageSize) => {
  return fetchBase(
    "GET",
    `${endpoint}/explorer/post/reactions/${type}?startTime=${startTime}&pageSize=${pageSize}`,
    null
  );
};

// POST /explorer/post/views
export const markViewedPost = async (payload) => {
  return fetchBase("POST", `${endpoint}/explorer/post/views`, payload);
};

// POST /explorer/post/:pid/reactions
export const addPostReaction = async (post_id, reaction_type) => {
  return fetchBase("POST", `${endpoint}/explorer/post/${post_id}/reactions`, {
    reaction_type,
  });
};

// DELETE explorer/post/:id/reactions
export const removePostReaction = async (post_id, reaction_type) => {
  return fetchBase("DELETE", `${endpoint}/explorer/post/${post_id}/reactions`, {
    reaction_type,
  });
};

// GET /explorer/post/search?query=b&pageSize=10
export const searchPosts = async (valueSearch, pageSize) => {
  return fetchBase("GET", `${endpoint}/explorer/post/search?query=${valueSearch}&pageSize=${pageSize}`, null);
};
