import { useState } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Flex, Input, Button, message } from "antd";
import { SendOutlined, UserOutlined, LoginOutlined } from "@ant-design/icons";
import { useAuth } from "../../../components/contexts/AuthContext";
import { addComment } from "../../../services/supabaseClient";
import useWindowSize from "../../../components/hooks/useWindowSize";

const { TextArea } = Input;

const CommentArea = ({ onAddComment, totalComment }) => {
  const { isMobileView } = useWindowSize();
  const { perfumeId } = useParams();
  const { user, openLoginModal } = useAuth();
  const [valueComment, setValueComment] = useState(null);
  const [sending, setSending] = useState(false);

  const doComment = async () => {
    if (!valueComment || !valueComment.trim()) return;
    if (sending) {
      message.warning("Please wait before submitting another comment.");
      return;
    }

    const comment = {
      comment: valueComment.trim(),
      mentioned_user_ids: [],
      parent_id: null,
      topic: perfumeId,
    };

    try {
      setSending(true);
      const { error, data } = await addComment(comment);
      if (error) {
        message.error(error.message);
      } else {
        setValueComment(null);
        onAddComment(data[0]);
        message.success("Comment successfully!");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSending(false);
    }
  };

  const textAreaPlaceholder = user
    ? totalComment > 0
      ? `Share your thoughts for perfume ...`
      : "Write first review for perfume ..."
    : "Sign in to share your thoughts...";

  return (
    <Flex align="center">
      {user ? (
        <div>
          <Avatar size="large" src={user.vfra_user?.avatar ?? user.avatar_url} />
        </div>
      ) : (
        <div>
          <Avatar onClick={openLoginModal} size="large" icon={<UserOutlined />} />
        </div>
      )}

      <TextArea
        style={{ paddingLeft: "0.75rem", fontSize: "0.9rem" }}
        placeholder={textAreaPlaceholder}
        autoSize={{
          minRows: 1,
        }}
        maxLength={2000}
        variant="borderless"
        value={valueComment}
        onChange={(e) => setValueComment(e.target.value)}
      />
      {user ? (
        <Button
          size={isMobileView ? "small" : "default"}
          disabled={sending}
          loading={sending}
          onClick={doComment}
          shape="circle"
          icon={<SendOutlined />}
        />
      ) : (
        <Button
          shape="round"
          size={isMobileView ? "small" : "default"}
          onClick={openLoginModal}
          icon={<LoginOutlined />}>
          {!isMobileView && "Sign in"}
        </Button>
      )}
    </Flex>
  );
};

export default CommentArea;
