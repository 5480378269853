import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, message, Typography, Modal, Dropdown, Skeleton } from "antd";
import {
  LikeOutlined,
  HeartOutlined,
  MoreOutlined,
  LikeFilled,
  HeartFilled,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext";
import {
  HEART_REACTION,
  LIKE_REACTION,
  ADD_REACTION_ACTION,
  DELETE_REACTION_ACTION,
  convertComment,
} from "../../../utils/service";
import ImageWithHandler from "../ImageWithHandler";
import CommentReplyArea from "../CommentReplyArea";
const PAGE_SIZE = 5;

const { Paragraph } = Typography;
const ownerItems = [
  {
    label: "Delete",
    key: "delete",
  },
];

const Comment = ({
  data,
  topic,
  sizeAvt = "large",
  isSmallView = false,
  onDeleteComment,
  addFunc,
  deleteFunc,
  getCommentsFunc,
  addReactionFunc,
  deleteReactionFunc,
}) => {
  const navigate = useNavigate();
  const { user, openLoginModal } = useAuth();
  const [item, setItem] = useState(data);
  const [repliesCount, setRepliesCount] = useState(data.replies_count);

  const [showReplyArea, setShowReplyArea] = useState(false);
  const [showReplyComments, setShowReplyComments] = useState(false);

  const onMenuClick = (key) => {
    if (key === "delete") {
      showDeleteConfirm();
    }
  };

  const showDeleteConfirm = () => {
    return Modal.confirm({
      title: "Confirm delete comment",
      content: "Delete your comment permanently?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        doDeleteComment();
      },
      getContainer: document.getElementById("root"),
    });
  };

  const doDeleteComment = async () => {
    try {
      const response = await deleteFunc(item.id);
      if (response.status !== 204) {
        message.error(response.message);
      } else {
        message.success("Comment deleted!");
        onDeleteComment(item.id);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCommentReaction = (action, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (action === "ADD_REACTION") {
      doAddCommentReaction(reactionType);
    } else {
      doRemoveCommentReaction(reactionType);
    }
  };

  const doAddCommentReaction = async (reactionType) => {
    const payload = {
      comment_id: item.id,
      reaction_type: reactionType,
    };

    try {
      onChangeCommentReaction(reactionType, ADD_REACTION_ACTION);
      addReactionFunc(payload);
    } catch (error) {
      console.log(error.message);
    }
  };

  const doRemoveCommentReaction = async (reactionType) => {
    try {
      onChangeCommentReaction(reactionType, DELETE_REACTION_ACTION);
      deleteReactionFunc(reactionType, item.id);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onChangeCommentReaction = (reactionType, action) => {
    if (reactionType === HEART_REACTION) {
      if (action === ADD_REACTION_ACTION) {
        item.hearts_count++;
        item.active_for_user_heart = true;
      } else if (action === DELETE_REACTION_ACTION) {
        item.hearts_count--;
        item.active_for_user_heart = false;
      }
    } else if (reactionType === LIKE_REACTION) {
      if (action === ADD_REACTION_ACTION) {
        item.likes_count++;
        item.active_for_user_like = true;
      } else if (action === DELETE_REACTION_ACTION) {
        item.likes_count--;
        item.active_for_user_like = false;
      }
    }

    setItem({ ...item });
  };

  //hanlde reply comments
  const handleShowReplyArea = () => {
    if (!user) {
      openLoginModal();
      return;
    }

    setShowReplyArea(true);
  };

  //handle reply
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [replyDataComments, setReplyDataComments] = useState(null);

  const fetchDataComments = useCallback(
    async (startTime = 0) => {
      try {
        if (startTime === 0) {
          setLoading(true);
        } else {
          setLoadingMore(true);
        }

        const res = await getCommentsFunc(topic, startTime, PAGE_SIZE, data.id);
        if (res.error) {
          message.error(res.error.message);
        } else {
          const convertedData = res.data.map((e) => {
            return convertComment(e);
          });

          setReplyDataComments((prev) => {
            if (startTime !== 0) {
              return [...prev, ...convertedData];
            }
            return convertedData;
          });
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime === 0) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
      }
    },
    [topic, data, getCommentsFunc]
  );

  useEffect(() => {
    if (showReplyComments && !replyDataComments) fetchDataComments();
  }, [fetchDataComments, showReplyComments, replyDataComments]);

  const handleLoadMore = () => {
    const startTime =
      replyDataComments.length > 0 ? replyDataComments[replyDataComments.length - 1].created_at_original : 0;
    const convertedStartTime = new Date(startTime).getTime();
    fetchDataComments(convertedStartTime);
  };

  const handleAddReply = (newComments) => {
    setShowReplyArea(false);
    setRepliesCount((prev) => prev + 1);

    const convertedNewComments = convertComment(newComments);
    if (showReplyComments) {
      setReplyDataComments((prev) => [convertedNewComments, ...prev]);
    } else {
      if (replyDataComments) {
        setReplyDataComments((prev) => [convertedNewComments, ...prev]);
      } else {
        setReplyDataComments([convertedNewComments]);
      }

      setShowReplyComments(true);
    }
  };

  const handleDeleteComment = (id) => {
    setReplyDataComments((prev) => prev.filter((c) => c.id !== id));
    setRepliesCount((prev) => prev - 1);
  };

  const gapItem = isSmallView ? "0.5rem" : "0.75rem";
  const sizeAction = "1rem";

  return (
    <Flex vertical gap={"0.5rem"} style={{ borderBottom: "1px solid rgb(5 5 5 / 6%)", padding: "0.5rem 0" }}>
      <Flex gap={gapItem} align="center">
        <div style={{ cursor: "pointer" }}>
          <ImageWithHandler
            onClick={() => navigate(`/profile/${item.user_id}`)}
            size={sizeAvt}
            src={item.user_avatar}
            title={item.user_name ? item.user_name[0] : ""}
          />
        </div>

        <Flex justify="space-between" align="center" style={{ flexGrow: 1 }}>
          <Flex vertical="vertical">
            <span
              style={{ fontWeight: "700", cursor: "pointer", fontSize: isSmallView ? "0.8125rem" : "1rem" }}
              onClick={() => navigate(`/profile/${item.user_id}`)}>
              {item.user_name}
            </span>
            <span
              style={{
                fontWeight: "normal",
                fontSize: isSmallView ? "0.7rem" : "0.75rem",
                color: "var(--gray-color)",
              }}>
              {item.created_at}
            </span>
          </Flex>

          {user && user.user_id === item.user_id && (
            <Dropdown
              menu={{
                items: ownerItems,
                onClick: ({ key, domEvent }) => {
                  domEvent.stopPropagation();
                  onMenuClick(key);
                },
              }}
              placement="bottomLeft"
              trigger={["click"]}>
              <Button onClick={(e) => e.stopPropagation()} shape="circle" size={"small"} icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </Flex>
      </Flex>

      <Flex vertical>
        <Paragraph
          style={{ fontSize: isSmallView ? "0.8125rem" : "0.9rem", margin: 0 }}
          ellipsis={{ rows: 5, expandable: "collapsible", symbol: (expanded) => (expanded ? "less" : "more") }}>
          {item.comment}
        </Paragraph>

        <Flex gap={"0.25rem"}>
          <Button
            size={isSmallView ? "small" : "middle"}
            shape="round"
            style={{ fontSize: isSmallView ? "" : "0.9rem" }}
            type="text"
            onClick={() =>
              item.active_for_user_heart
                ? handleCommentReaction("REMOVE_REACTION", HEART_REACTION)
                : handleCommentReaction("ADD_REACTION", HEART_REACTION)
            }
            icon={
              item.active_for_user_heart ? (
                <HeartFilled style={{ color: "hotpink", fontSize: isSmallView ? "" : sizeAction }} />
              ) : (
                <HeartOutlined style={{ fontSize: isSmallView ? "" : sizeAction }} />
              )
            }>
            {item.hearts_count}
          </Button>
          <Button
            size={isSmallView ? "small" : "middle"}
            shape="round"
            style={{ fontSize: isSmallView ? "" : "0.9rem" }}
            type="text"
            onClick={() =>
              item.active_for_user_like
                ? handleCommentReaction("REMOVE_REACTION", LIKE_REACTION)
                : handleCommentReaction("ADD_REACTION", LIKE_REACTION)
            }
            icon={
              item.active_for_user_like ? (
                <LikeFilled style={{ color: "#1677ff", fontSize: isSmallView ? "" : sizeAction }} />
              ) : (
                <LikeOutlined style={{ fontSize: isSmallView ? "" : sizeAction }} />
              )
            }>
            {item.likes_count}
          </Button>
          <Button
            size={isSmallView ? "small" : "middle"}
            shape="round"
            onClick={handleShowReplyArea}
            style={{ fontSize: isSmallView ? "0.7rem" : "0.75rem", fontWeight: 500, color: "var(--gray-color)" }}
            type="text">
            Reply
          </Button>
        </Flex>

        {showReplyArea && (
          <CommentReplyArea
            isSmallView={isSmallView}
            data={item}
            topic={topic}
            onCancel={() => setShowReplyArea(false)}
            onAddComment={handleAddReply}
            addFunc={addFunc}
            style={{ paddingLeft: isSmallView ? "1.5rem" : "3rem", marginTop: "0.25rem" }}
          />
        )}

        {repliesCount > 0 && (
          <Flex style={{ paddingLeft: isSmallView ? "0.8rem" : "2.3rem", marginTop: "0.25rem" }}>
            <Button
              size="small"
              shape="round"
              onClick={() => setShowReplyComments((prev) => !prev)}
              style={{ fontSize: "0.75rem", fontWeight: 500, color: "#3f51b5" }}
              type="text"
              icon={showReplyComments ? <UpOutlined /> : <DownOutlined />}>
              {repliesCount} replies
            </Button>
          </Flex>
        )}

        {showReplyComments && (
          <Flex
            vertical
            gap={"0.5rem"}
            style={{
              borderLeft: "1px solid rgb(5 5 5 / 6%)",
              marginLeft: isSmallView ? "0.75rem" : "1.5rem",
              paddingLeft: isSmallView ? "0.75rem" : "1.5rem",
              marginTop: "0.25rem",
            }}>
            {loading && (
              <Skeleton
                style={{ marginTop: "0.25rem" }}
                active={true}
                avatar
                paragraph={{
                  rows: 1,
                }}
              />
            )}

            {!loading &&
              replyDataComments &&
              replyDataComments.map((item) => (
                <Comment
                  key={item.id}
                  sizeAvt={sizeAvt}
                  data={item}
                  isSmallView={isSmallView}
                  topic={topic}
                  onDeleteComment={handleDeleteComment}
                  addFunc={addFunc}
                  deleteFunc={deleteFunc}
                  getCommentsFunc={getCommentsFunc}
                  addReactionFunc={addReactionFunc}
                  deleteReactionFunc={deleteReactionFunc}
                />
              ))}

            {!loading && replyDataComments && replyDataComments.length < repliesCount && (
              <Flex style={{ paddingLeft: isSmallView ? "0.8rem" : "2.3rem", marginTop: "0.25rem" }}>
                <Button
                  loading={loadingMore}
                  onClick={handleLoadMore}
                  size="small"
                  shape="round"
                  style={{ fontSize: "0.75rem", fontWeight: 500, color: "#3f51b5" }}
                  type="text">
                  show more replies
                </Button>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Comment;
