import { Flex, Progress } from "antd";
import CustomeSvgIcon from "../../../../../components/common/CustomSvgIcon";
import CustomeSecondLabel from "../../../../../components/common/CustomeSecondLabel";
import { colors } from "../../../../../utils/service";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const progressStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "8px",
};

const styleIconActive = { marginBottom: "0.5rem", fill: colors.mainThemeColor };

const porgressPercentPosition = {
  type: "none",
};

const SeasonOrWeather = ({ name, value, icon, percent }) => {
  const { isMobileView } = useWindowSize();

  const progressSize = [isMobileView ? 60 : 70, 3];
  const iconHeight = !isMobileView ? "1.875rem" : 20;

  return (
    <Flex vertical align="center">
      <Flex vertical align="center" className="vfra-vote-value" style={{ width: "100%" }}>
        <CustomeSvgIcon style={styleIconActive} height={iconHeight} icon={icon} />
        <Flex vertical align="center">
          <span>{name}</span>
          <CustomeSecondLabel label={value} />
        </Flex>
      </Flex>

      <Progress
        style={progressStyle}
        percent={percent}
        percentPosition={porgressPercentPosition}
        size={progressSize}
        strokeColor={colors.mainThemeColor}
      />
    </Flex>
  );
};

export default SeasonOrWeather;
