import { Col, Row, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import PerfumeDetail from "./PerfumeDetail";
import Comments from "./Comments";
import useWindowSize from "../../components/hooks/useWindowSize";
import RelatedPerfumes from "./RelatedPerfumes";
// import Popupsmart from "../../components/common/PopupSmart";

const Perfume = () => {
  const { isMobileView } = useWindowSize();

  return (
    <>
      {/* <Popupsmart /> */}
      <Breadcrumb
        style={{ display: isMobileView ? "block" : "none" }}
        items={[
          {
            href: "/home",
            title: <HomeOutlined />,
          },
          {
            title: "Perfume",
          },
        ]}
      />

      {isMobileView && <br></br>}

      <Row gutter={[24, 12]} justify={"center"}>
        <Col span={24}>
          <PerfumeDetail />
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Comments />
        </Col>
        <Col span={24}>
          <RelatedPerfumes />
        </Col>
      </Row>
    </>
  );
};

export default Perfume;
