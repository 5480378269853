import "./index.css";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex, message, Button, Modal } from "antd";
import { getActitityById } from "../../../../services/supabaseClient";
import { popularOcassionIds } from "../../../../utils/ocassion";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import DynamicChart from "../../../../components/common/DynamicChart";
import AllOcassions from "./AllOcassions";

const Occasion = ({ noteCount }) => {
  const { perfumeId } = useParams();
  const { isMobileView } = useWindowSize();
  const [allOcassions, setAllOcassions] = useState([]);
  const [popularOcassions, setPopularOcassions] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [openAllOcassion, setOpenAllOcassion] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const { data, error } = await getActitityById(perfumeId);

      if (error) {
        message.error(error.message);
      } else {
        setAllOcassions(data);

        let max = 0;

        const listOcassions = [];
        popularOcassionIds.forEach((oId) => {
          const tempOcassion = data.find((e) => e.activity_id === oId);

          const convertedScore = (tempOcassion.activity_score / noteCount) * 10;
          if (convertedScore > max) max = convertedScore;

          listOcassions.push({
            name: `${tempOcassion.a.activity_name} (${convertedScore.toFixed(1)})`,
            value: parseFloat(convertedScore.toFixed(1)),
          });
        });

        setMaxValue(max);

        setPopularOcassions(listOcassions);
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [perfumeId, noteCount]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Flex vertical justify="center">
      {popularOcassions.length > 0 && (
        <DynamicChart
          maxValue={maxValue}
          data={popularOcassions}
          style={{ height: isMobileView ? "35vh" : "30vh", width: "100%" }}
        />
      )}
      <Flex justify="center">
        <Button onClick={() => setOpenAllOcassion(true)} size="small" shape="round">
          All ocassions
        </Button>
      </Flex>
      <Modal
        title={<span style={{ fontSize: "1.3rem" }}>All Ocassions ({allOcassions.length})</span>}
        width={isMobileView ? "100%" : "40%"}
        centered
        open={openAllOcassion}
        onCancel={() => setOpenAllOcassion(false)}
        footer={null}
        closable={true}
        getContainer={false}>
        <AllOcassions ocassions={allOcassions} noteCount={noteCount} />
      </Modal>
    </Flex>
  );
};

export default Occasion;
