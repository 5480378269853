import "./index.css";
import { useEffect, useState, useCallback } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Row, Col, Divider, Button, Modal, Flex } from "antd";
import { optionDataVotes, initDataVotes } from "../../../../utils/data";
import { winterIcon, leafIcon, summerIcon, fallIcon, sonIcon, moonIcon } from "../../../../utils/svgIcons";
import { getMaxValueFromObject } from "../../../../utils/service";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import Vote from "./Vote";
import GenderVote from "./Gender";
import VotingModal from "./VotingModal";
import SeasonOrWeather from "./SeasonOrWeather";

const sampleSeasons = [
  {
    id: "spring",
    icon: leafIcon,
  },
  {
    id: "summer",
    icon: summerIcon,
  },
  {
    id: "fall",
    icon: fallIcon,
  },
  {
    id: "winter",
    icon: winterIcon,
  },
];

const sampleWeathers = [
  {
    id: "day",
    icon: sonIcon,
  },
  {
    id: "night",
    icon: moonIcon,
  },
];

const Votes = ({ perfumeName, dataVotes, dataVotesByUser, onChangVote }) => {
  const { isMobileView } = useWindowSize();

  const [seasonsData, setSeasonsData] = useState([]);
  const [weathersData, setWeathersData] = useState([]);
  const [gendersData, setGendersData] = useState(null);
  const [ortherVotesData, setOrtherVotesData] = useState(null);
  const [openVote, setOpenVote] = useState(false);

  const setInitDataVotes = useCallback((data) => {
    const initData = data ? data : initDataVotes;
    const { seasons, weathers, genders, longevity, sillage, price_value } = initData;

    const maxValueSeasons = getMaxValueFromObject(seasons);
    const convertedSeasons = sampleSeasons.map((e) => {
      return {
        ...e,
        value: seasons[`${e.id}`],
        percent: (seasons[`${e.id}`] / maxValueSeasons) * 100,
      };
    });
    setSeasonsData(convertedSeasons);

    const maxValueWeathers = getMaxValueFromObject(weathers);
    const convertedWeathers = sampleWeathers.map((e) => {
      return {
        ...e,
        value: weathers[`${e.id}`],
        percent: (weathers[`${e.id}`] / maxValueWeathers) * 100,
      };
    });
    setWeathersData(convertedWeathers);

    setGendersData(genders);

    const initOrtherVotes = {
      longevity,
      sillage,
      price_value,
    };

    setOrtherVotesData(initOrtherVotes);
  }, []);

  useEffect(() => {
    setInitDataVotes(dataVotes);
  }, [dataVotes, setInitDataVotes]);

  return (
    <>
      <Divider style={{ marginTop: !isMobileView ? "0" : "6px" }}>
        <Button
          onClick={() => setOpenVote(true)}
          size="small"
          shape="round"
          icon={<EditOutlined />}
          style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
          Votes
        </Button>
        <Modal
          title={<span style={{ fontSize: "1.3rem" }}>{perfumeName}</span>}
          width={isMobileView ? "100%" : "50%"}
          centered
          open={openVote}
          onCancel={() => setOpenVote(false)}
          footer={null}
          closable={true}
          getContainer={false}>
          <VotingModal
            onVoteSuccess={onChangVote}
            onCancel={() => setOpenVote(false)}
            dataVotesByUser={dataVotesByUser}
          />
        </Modal>
      </Divider>

      <Row gutter={[0, !isMobileView ? 16 : 24]} style={{ paddingTop: "0.5rem" }}>
        <Col span={24}>
          <Row gutter={isMobileView ? [0, 24] : [0, 0]}>
            <Col span={!isMobileView ? 16 : 24}>
              <Flex justify="space-around">
                {seasonsData.map((e) => (
                  <SeasonOrWeather key={e.id} name={e.id} value={e.value} icon={e.icon} percent={e.percent} />
                ))}
              </Flex>
            </Col>
            <Col span={!isMobileView ? 8 : 24}>
              <Flex justify="space-around">
                {weathersData.map((e) => (
                  <SeasonOrWeather key={e.id} name={e.id} value={e.value} icon={e.icon} percent={e.percent} />
                ))}
              </Flex>
            </Col>
          </Row>
        </Col>

        {optionDataVotes.map((vote) => {
          return (
            <Col key={vote.name} span={8}>
              <Vote data={ortherVotesData} vote={vote} />
            </Col>
          );
        })}

        <Col span={24} style={{ marginTop: "0.75rem" }}>
          <GenderVote data={gendersData} />
        </Col>
      </Row>
    </>
  );
};

export default Votes;
