import "./index.css";
import { Row, Col, Flex } from "antd";
import useWindowSize from "../../../../../../components/hooks/useWindowSize";

const ListItems = ({ list }) => {
  const { isMobileView } = useWindowSize();
  return (
    <Row gutter={[16, 16]} justify="center" vertical="vertical">
      {list.map((item) => {
        return (
          <Col style={{ padding: "0 0.5rem" }} span={isMobileView ? 12 : 8} key={item.activity_id}>
            <Flex justify="center" align="center" className="vfra-ocassion-item">
              <span className="one-line-ellipsis" style={{ fontSize: "0.8125rem", fontWeight: "400" }}>
                {item.a.activity_name}
              </span>
            </Flex>
          </Col>
        );
      })}
    </Row>
  );
};

export default ListItems;
