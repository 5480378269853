import "./index.css";
import { useState, useEffect, useCallback } from "react";
import { Divider, Flex } from "antd";
import VerticalProgress from "../../../../../components/common/VerticalProgress/VerticalProgress";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const Vote = ({ vote, data }) => {
  const { isMobileView } = useWindowSize();

  const [dataVote, setDataVote] = useState(null);

  const [valueShow, setValueShow] = useState(0);

  const setInitValues = useCallback(
    (dataInit) => {
      setDataVote(dataInit);

      const percent = dataInit ? (dataInit[vote.id]?.value / (vote.list.length - 1)) * 100 : 0;
      setValueShow(percent.toFixed(0));
    },
    [vote]
  );

  useEffect(() => {
    setInitValues(data);
  }, [data, setInitValues]);

  const voteHeight = !isMobileView ? "11.25rem" : 130;
  const totalVote = dataVote && vote && dataVote[vote.id]?.count ? dataVote[vote.id]?.count : 0;

  return (
    <>
      <Divider>
        <span style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          {vote.name} <span style={{ fontSize: "0.7rem", color: "rgb(78, 76, 76)" }}>({totalVote})</span>
        </span>
      </Divider>

      <Flex justify="center" gap={"1rem"}>
        <Flex vertical justify="space-between" style={{ height: voteHeight }}>
          {vote.list.map((item, index) => {
            return (
              <span key={item.title} value={vote.list.length - index - 1} className="vfra-radio-style">
                {item.title}
              </span>
            );
          })}
        </Flex>
        <VerticalProgress percent={valueShow} height={voteHeight} width={"3px"} showText={true} />
      </Flex>
    </>
  );
};

export default Vote;
