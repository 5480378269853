import "./index.css";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex, Divider, Row, Col, Radio, Steps, Button, message, Result, Modal } from "antd";
// import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { addVotes } from "../../../../../services/supabaseClient";
import { useAuth } from "../../../../../components/contexts/AuthContext";
import { longevityOptions, sillageOptions, priceOptions, genderOptions } from "../../../../../utils/data";
import SeasonVote from "./Season";
import WeatherVote from "./Weather";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

// const mockDataOption = [
//   { name: "Daily", value: 10 },
//   { name: "Sport", value: 30 },
//   { name: "Leisure", value: 5 },
//   { name: "Night Out", value: 40 },
//   { name: "Leisure", value: 5 },
//   { name: "Business", value: 3 },
//   { name: "Evening", value: 35 },
//   { name: "Sport", value: 30 },
//   { name: "Daily", value: 10 },
//   { name: "Sport", value: 30 },
//   { name: "Night Out", value: 40 },
//   { name: "Business", value: 3 },
// ];

const dividerStyle = {
  margin: "0.25rem 0",
};

const getUserVote = (field, data) => {
  if (!data || !data[field]) return null;
  return Object.keys(data[field])[0];
};

const VotingModal = ({ dataVotesByUser, onCancel, onVoteSuccess }) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { perfumeId } = useParams();

  // const [selectedOcassions, setSelectedOcassions] = useState([]);
  const [userVotesData, setUserVotesData] = useState({});
  const [changedVote, setChangedVote] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const setInitDataVotesByUser = useCallback(() => {
    const seasons_vote = dataVotesByUser?.seasons ?? null;
    const weathers_vote = dataVotesByUser?.weathers ?? null;
    const genders_vote = getUserVote("genders", dataVotesByUser);

    const userVotes = {
      seasons: seasons_vote,
      weathers: weathers_vote,
      genders: genders_vote,
      longevity: dataVotesByUser?.longevity ?? null,
      sillage: dataVotesByUser?.sillage ?? null,
      price_value: dataVotesByUser?.price_value ?? null,
    };

    setUserVotesData(userVotes);
  }, [dataVotesByUser]);

  useEffect(() => {
    setInitDataVotesByUser();
  }, [setInitDataVotesByUser]);

  // const onSelectOcassion = (value) => {
  //   const isExist = selectedOcassions.includes(value);
  //   if (isExist) {
  //     setSelectedOcassions((prev) => prev.filter((e) => e !== value));
  //   } else {
  //     if (selectedOcassions.length === 6) return;
  //     setSelectedOcassions((prev) => [...prev, value]);
  //   }
  // };

  const onChangeVote = (type, value) => {
    setUserVotesData((prev) => {
      if (prev[type] && prev[type].hasOwnProperty(value)) {
        prev[type][value] = !prev[type][value];
      } else {
        prev[type] = {
          ...prev[type],
          [value]: true,
        };
      }

      return { ...prev };
    });
    setChangedVote(true);
  };

  const onChangeGender = (e) => {
    setUserVotesData((prev) => {
      prev.genders = e.target.value;
      return { ...prev };
    });
    setChangedVote(true);
  };

  const onChangeRating = (type, value) => {
    setUserVotesData((prev) => {
      prev[type] = value;
      return { ...prev };
    });
    setChangedVote(true);
  };

  const onSubmitVote = async () => {
    if (!user) message.warning("Please sign in to vote!");
    else {
      setIsSubmitting(true);
      try {
        const convertedData = {
          ...userVotesData,
          genders: {
            [userVotesData.genders]: true,
          },
        };

        const { error } = await addVotes(perfumeId, convertedData);

        if (error) {
          message.error(error.message);
        } else {
          onVoteSuccess();
          // message.success("Your vote was succesfully!");
          setOpenSuccessModal(true);
          setTimeout(() => {
            setOpenSuccessModal(false);
          }, 4000);
          setChangedVote(false);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Flex vertical gap={isMobileView ? "0.75rem" : "1.25rem"}>
      {/* Ocassions */}
      {/* <Flex vertical gap="0.75rem">
        <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
          <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Ocassions</span>
          <span style={{ fontSize: "0.7rem", marginLeft: "0.5rem" }}>(6 options)</span>
        </Divider>
        <Flex wrap gap={"0.75rem"} justify="center">
          {mockDataOption.map((e, i) => {
            const isSelected = selectedOcassions.includes(i);

            return (
              <Flex
                key={i}
                onClick={() => onSelectOcassion(i)}
                className={
                  isSelected
                    ? "vfra-ocassion-option-active"
                    : selectedOcassions.length === 6
                    ? "vfra-ocassion-option-disabled"
                    : "vfra-ocassion-option"
                }
                gap={"0.25rem"}
                align="center">
                <span>{e.name}</span>
                {!isSelected && <PlusOutlined style={{ fontWeight: 500 }} />}
                {isSelected && <CheckOutlined style={{ fontWeight: 500 }} />}
              </Flex>
            );
          })}
        </Flex>
      </Flex> */}

      {/* Seasons & Weathers */}
      <Flex vertical gap="0.75rem">
        <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
          <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Seasons & Weathers</span>
          <span style={{ fontSize: "0.7rem", marginLeft: "0.5rem" }}>(multiple)</span>
        </Divider>
        <Row gutter={isMobileView ? [0, 24] : [0, 0]}>
          <Col span={!isMobileView ? 16 : 24}>
            <SeasonVote dataUserVote={userVotesData} onUserVote={onChangeVote} />
          </Col>
          <Col span={!isMobileView ? 8 : 24}>
            <WeatherVote dataUserVote={userVotesData} onUserVote={onChangeVote} />
          </Col>
        </Row>
      </Flex>

      {isMobileView && (
        <>
          <Divider style={{ margin: "0" }} />
          <Flex justify="space-around">
            {/* Longevity */}
            <Flex vertical gap="0.75rem">
              <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
                <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Longevity</span>
              </Divider>
              <Steps
                direction="vertical"
                progressDot
                onChange={(value) => onChangeRating("longevity", value)}
                current={userVotesData.longevity}
                size="small"
                items={longevityOptions}
              />
            </Flex>

            {/* Sillage */}
            <Flex vertical gap="0.75rem">
              <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
                <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Sillage</span>
              </Divider>
              <Steps
                direction="vertical"
                progressDot
                onChange={(value) => onChangeRating("sillage", value)}
                current={userVotesData.sillage}
                size="small"
                items={sillageOptions}
              />
            </Flex>

            {/* Price */}
            <Flex vertical gap="0.75rem">
              <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
                <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Price</span>
              </Divider>
              <Steps
                direction="vertical"
                progressDot
                onChange={(value) => onChangeRating("price_value", value)}
                current={userVotesData.price_value}
                size="small"
                items={priceOptions}
              />
            </Flex>
          </Flex>
          <Divider style={{ margin: "0" }} />
        </>
      )}

      {!isMobileView && (
        <>
          {/* Longevity */}
          <Flex vertical gap="0.75rem">
            <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
              <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Longevity</span>
            </Divider>
            <Steps
              progressDot
              onChange={(value) => onChangeRating("longevity", value)}
              current={userVotesData.longevity}
              size="small"
              items={longevityOptions}
            />
          </Flex>

          {/* Sillage */}
          <Flex vertical gap="0.75rem">
            <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
              <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Sillage</span>
            </Divider>
            <Steps
              progressDot
              onChange={(value) => onChangeRating("sillage", value)}
              current={userVotesData.sillage}
              size="small"
              items={sillageOptions}
            />
          </Flex>

          {/* Price */}
          <Flex vertical gap="0.75rem">
            <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
              <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Price</span>
            </Divider>
            <Steps
              progressDot
              onChange={(value) => onChangeRating("price_value", value)}
              current={userVotesData.price_value}
              size="small"
              items={priceOptions}
            />
          </Flex>
        </>
      )}

      {/* Gender */}
      <Flex vertical gap="0.75rem">
        <Divider orientation="left" orientationMargin="0" style={dividerStyle}>
          <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Genders</span>
        </Divider>
        <Flex justify="center">
          <Radio.Group
            block
            options={genderOptions}
            value={userVotesData.genders}
            optionType="button"
            onChange={onChangeGender}
          />
        </Flex>
      </Flex>

      {/* Buttons */}
      <Flex justify="end" align="center" gap={"0.75rem"}>
        <Button onClick={onCancel} disabled={isSubmiting} type="text" shape="round">
          Cancel
        </Button>
        <Button
          loading={isSubmiting}
          disabled={isSubmiting || !changedVote}
          onClick={onSubmitVote}
          type="primary"
          shape="round">
          Vote
        </Button>
      </Flex>

      {/* Success modal */}
      <Modal
        title={null}
        width="40%"
        centered
        open={openSuccessModal}
        footer={null}
        closable={true}
        onCancel={() => setOpenSuccessModal(false)}
        getContainer={false}>
        <Result
          status="success"
          title="Successfully Voting!"
          subTitle={
            <Flex justify="center" width="80%" style={{ overflowWrap: "break-word", whiteSpace: "normal" }}>
              Thank you for participating in this survey, your action will help people better understand perfumes.
            </Flex>
          }
        />
      </Modal>
    </Flex>
  );
};

export default VotingModal;
