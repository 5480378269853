import { Flex } from "antd";
import EzoicAd from "../../components/common/AdSences/EzoicAd";
const EzoicAds = () => {
  return (
    <Flex vertical gap={"1rem"}>
      <EzoicAd adPosition="115" />
      <EzoicAd adPosition="114" />
      <EzoicAd adPosition="113" />
      <EzoicAd adPosition="112" />
      <EzoicAd adPosition="111" />
      <EzoicAd adPosition="109" />
      <EzoicAd adPosition="108" />
      <EzoicAd adPosition="107" />
      <EzoicAd adPosition="106" />
    </Flex>
  );
};

export default EzoicAds;
