import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";

const DynamicChart = ({ maxValue, data, ...props }) => {
  const [indicator, setIndicator] = useState(null);
  const [dataShow, setDataShow] = useState(null);

  useEffect(() => {
    if (data) {
      const tempIndicator = [];
      const tempDataShow = [];
      data.forEach((e) => {
        tempIndicator.push({
          name: e.name,
          max: Math.floor(maxValue) + 1,
        });
        tempDataShow.push(e.value);
      });

      setIndicator(tempIndicator);
      setDataShow(tempDataShow);
    }
  }, [data, maxValue]);

  const chartOptions = {
    textStyle: {
      fontSize: "0.75rem",
      fontWeight: "400",
      color: "black",
    },
    tooltip: {
      trigger: "axis",
    },
    radar: {
      max: Math.floor(maxValue) + 1,
      indicator: indicator,
      splitNumber: Math.floor(maxValue) + 1,
    },
    series: [
      {
        color: "#141a16",
        type: "radar",
        tooltip: {
          trigger: "item",
        },
        // areaStyle: {},
        data: [
          {
            value: dataShow,
            name: "Ocassion",
          },
        ],
      },
    ],
  };

  if (!dataShow) {
    return <p>loading...</p>;
  }

  return <ReactECharts {...props} option={chartOptions} />;
};

export default DynamicChart;
