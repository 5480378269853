export const ocassions = [
  {
    activity_id: 10,
    activity_name: "Dinner Date (Romantic)",
    activity_score: 4.3,
  },
  {
    activity_id: 52,
    activity_name: "Journaling",
    activity_score: 3.6,
  },
  {
    activity_id: 2,
    activity_name: "Work (Creative)",
    activity_score: 4,
  },
  {
    activity_id: 40,
    activity_name: "Graduation Ceremony",
    activity_score: 3.5,
  },
  {
    activity_id: 13,
    activity_name: "Night Out (Bar/Pub)",
    activity_score: 4.8,
  },
  {
    activity_id: 14,
    activity_name: "Movie Night (Cinema)",
    activity_score: 3.1,
  },
  {
    activity_id: 43,
    activity_name: "Family Reunion",
    activity_score: 3.8,
  },
  {
    activity_id: 1,
    activity_name: "Work (Office)",
    activity_score: 2.6,
  },
  {
    activity_id: 25,
    activity_name: "Hiking",
    activity_score: 3.7,
  },
  {
    activity_id: 23,
    activity_name: "Attending a Sporting Event",
    activity_score: 4.2,
  },
  {
    activity_id: 30,
    activity_name: "Sightseeing (Nature)",
    activity_score: 3.8,
  },
  {
    activity_id: 9,
    activity_name: "Lunch with a Friend",
    activity_score: 3.8,
  },
  {
    activity_id: 19,
    activity_name: "Party (Formal)",
    activity_score: 5.2,
  },
  {
    activity_id: 22,
    activity_name: "Concert (Outdoor)",
    activity_score: 3.6,
  },
  {
    activity_id: 8,
    activity_name: "Volunteering",
    activity_score: 3.7,
  },
  {
    activity_id: 28,
    activity_name: "Picnic",
    activity_score: 3.3,
  },
  {
    activity_id: 47,
    activity_name: "Taking a Bath",
    activity_score: 3.9,
  },
  {
    activity_id: 45,
    activity_name: "Yoga/Meditation",
    activity_score: 3.8,
  },
  {
    activity_id: 12,
    activity_name: "Night Out (Dancing)",
    activity_score: 4.8,
  },
  {
    activity_id: 27,
    activity_name: "Beach Day",
    activity_score: 1.9,
  },
  {
    activity_id: 41,
    activity_name: "Job Interview",
    activity_score: 3.9,
  },
  {
    activity_id: 16,
    activity_name: "Game Night",
    activity_score: 4.9,
  },
  {
    activity_id: 32,
    activity_name: "Weekend Getaway",
    activity_score: 3.4,
  },
  {
    activity_id: 33,
    activity_name: "Vacation (Tropical)",
    activity_score: 2.7,
  },
  {
    activity_id: 49,
    activity_name: "At-Home Spa Night",
    activity_score: 3.6,
  },
  {
    activity_id: 34,
    activity_name: "Vacation (Adventure)",
    activity_score: 4.4,
  },
  {
    activity_id: 29,
    activity_name: "Sightseeing (City)",
    activity_score: 3.4,
  },
  {
    activity_id: 21,
    activity_name: "Concert (Classical)",
    activity_score: 4.6,
  },
  {
    activity_id: 17,
    activity_name: "Party (Birthday)",
    activity_score: 3.5,
  },
  {
    activity_id: 38,
    activity_name: "Wedding (Bride/Groom)",
    activity_score: 4.2,
  },
  {
    activity_id: 26,
    activity_name: "Camping",
    activity_score: 3.4,
  },
  {
    activity_id: 3,
    activity_name: "Commuting",
    activity_score: 2.7,
  },
  {
    activity_id: 31,
    activity_name: "Road Trip",
    activity_score: 3.3,
  },
  {
    activity_id: 15,
    activity_name: "Movie Night (Home)",
    activity_score: 3.9,
  },
  {
    activity_id: 11,
    activity_name: "Dinner Date (Casual)",
    activity_score: 3.8,
  },
  {
    activity_id: 6,
    activity_name: "Studying/Reading",
    activity_score: 4,
  },
  {
    activity_id: 44,
    activity_name: "Religious Ceremony",
    activity_score: 3.2,
  },
  {
    activity_id: 48,
    activity_name: "Reading in Bed",
    activity_score: 4,
  },
  {
    activity_id: 46,
    activity_name: "Spa Day",
    activity_score: 4.1,
  },
  {
    activity_id: 4,
    activity_name: "Running Errands",
    activity_score: 3.3,
  },
  {
    activity_id: 7,
    activity_name: "Working From Home",
    activity_score: 3.7,
  },
  {
    activity_id: 39,
    activity_name: "Anniversary Celebration",
    activity_score: 4.8,
  },
  {
    activity_id: 35,
    activity_name: "Gardening",
    activity_score: 3.3,
  },
  {
    activity_id: 42,
    activity_name: "First Date",
    activity_score: 3.4,
  },
  {
    activity_id: 51,
    activity_name: "Night Routine",
    activity_score: 4.6,
  },
  {
    activity_id: 36,
    activity_name: "Wine/Bear Tasting",
    activity_score: 3.9,
  },
  {
    activity_id: 5,
    activity_name: "Casual Coffee Date",
    activity_score: 3.1,
  },
  {
    activity_id: 18,
    activity_name: "Party (House)",
    activity_score: 4.3,
  },
  {
    activity_id: 50,
    activity_name: "Morning Routine",
    activity_score: 4.3,
  },
  {
    activity_id: 20,
    activity_name: "Theatre/Opera",
    activity_score: 4.4,
  },
  {
    activity_id: 24,
    activity_name: "Gallery/Museum Visit",
    activity_score: 4.1,
  },
  {
    activity_id: 37,
    activity_name: "Wedding (Guest)",
    activity_score: 4,
  },
];

export const top6Ocassion = [
  {
    activity_id: 19,
    activity_name: "Party (Formal)",
    activity_score: 5.2,
  },
  {
    activity_id: 16,
    activity_name: "Game Night",
    activity_score: 4.9,
  },
  {
    activity_id: 13,
    activity_name: "Night Out (Bar/Pub)",
    activity_score: 4.8,
  },
  {
    activity_id: 12,
    activity_name: "Night Out (Dancing)",
    activity_score: 4.8,
  },
  {
    activity_id: 39,
    activity_name: "Anniversary Celebration",
    activity_score: 4.8,
  },
];

export const getTop6Ocassion = () => {
  const sortedData = ocassions.sort((a, b) => b.activity_score - a.activity_score);
  return sortedData.slice(0, 5);
};

export const getTopNOcassion = (data, n) => {
  const sortedData = data.sort((a, b) => b.activity_score - a.activity_score);
  return sortedData.slice(0, n);
};

export const popularOcassionIds = [1, 10, 28, 6, 50];
