import "./index.css";
import { useEffect, useState } from "react";
import { Col, Row, Flex, Tabs, Badge, Divider } from "antd";
import useWindowSize from "../../../../../components/hooks/useWindowSize";
import ListItems from "./ListItems";

// const colors_sample = ["green", "blue", "purple", "magenta"];

const AllOcassions = ({ ocassions, noteCount }) => {
  const { isMobileView } = useWindowSize();
  const [ocassionsData, setOcassionsData] = useState([]);

  useEffect(() => {
    const convertedOcassions = ocassions.sort((a, b) => b.activity_score - a.activity_score);
    setOcassionsData(convertedOcassions);
  }, [ocassions]);

  if (ocassionsData.length === 0) return;

  const items = [
    {
      label: "Good",
      key: "0",
      children: <ListItems list={ocassionsData.slice(4, 19)} noteCount={noteCount} />,
    },
    {
      label: "Neutral",
      key: "1",
      children: <ListItems list={ocassionsData.slice(19, 34)} noteCount={noteCount} />,
    },
    {
      label: "Bad",
      key: "2",
      children: <ListItems list={ocassionsData.slice(34, 52)} noteCount={noteCount} />,
    },
  ];

  return (
    <Flex gap={"1rem"} vertical>
      <Divider orientation="left" orientationMargin="0" style={{ margin: "0" }}>
        <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Top ocassions</span>
      </Divider>
      <Row gutter={[16, 16]}>
        {ocassionsData.slice(0, 4).map((item, i) => {
          return (
            <Col span={isMobileView ? 12 : 6} key={item.activity_id}>
              <Flex
                vertical
                align="center"
                justify="space-between"
                gap={"0.35rem"}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #e5e5e5",
                  borderRadius: "0.75rem",
                  padding: "1rem",
                }}>
                <span style={{ fontSize: "0.8125rem", textAlign: "center", fontWeight: 600 }}>
                  {item.a.activity_name}
                </span>
                {/* <Badge color={colors_sample[i]} count={((item.activity_score / noteCount) * 10).toFixed(1)} /> */}

                <Badge
                  style={{ fontWeight: "600" }}
                  color={"var(--main-theme-color)"}
                  count={((item.activity_score / noteCount) * 10).toFixed(1)}
                />
              </Flex>
            </Col>
          );
        })}
      </Row>
      <Tabs size={isMobileView ? "small" : "middle"} type="card" items={items} />
    </Flex>
  );
};

export default AllOcassions;
